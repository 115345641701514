import { useTranslation } from "react-i18next"
import * as React from "react"

import { BoltaEvent } from "@/utils/mixpanels"
import { useProfileStore } from "@/store/profiles"

export type CertificationMessage = {
  message: string
  type: "success" | "danger"
  event?: () => void
}

/**
 * - 0000: 인증서 등록 성공
 * - 0001: 사업자등록번호와 일치하지 않은 인증서
 * - 0002: 세금계산서 발행이 가능한 인증서로 등록해 주세요.
 * - 9999: 예상하지 못한 오류가 발생했습니다.
 */
export type CertificationResultCode = "0000" | "0001" | "0002" | "9999"

type CustomCertificationMessage = Pick<CertificationMessage, "message" | "type">
export type CustomCertificationMessages = Record<
  CertificationResultCode,
  CustomCertificationMessage
>

export type UseCertificationMessageProps = {
  messages?: Partial<CustomCertificationMessages>
}

export function useCertificationMessage({
  messages,
}: UseCertificationMessageProps = {}) {
  const { t } = useTranslation()
  const workspaceId = useProfileStore(state => state.getCurrentWorkspaceId())
  const latestMessagesRef = React.useRef<Partial<CustomCertificationMessages>>(
    messages ?? {},
  )

  const getCertificationMessage = React.useCallback(
    (code: CertificationResultCode): CertificationMessage | null => {
      const customMessage = latestMessagesRef.current?.[code]
      switch (code) {
        case "0000": {
          return {
            message: customMessage?.message ?? t("certification.success.register"),
            type: customMessage?.type ?? "success",
            event: () => {
              BoltaEvent.track("certificate_register_success", {
                workspaceId,
              })
            },
          }
        }
        case "0001": {
          return {
            message:
              customMessage?.message ?? t("certification.error.businessNumber"),
            type: customMessage?.type ?? "danger",
            event: () => {
              BoltaEvent.track(
                "certificate_register_fail",
                { workspaceId },
                {
                  cause: "사업자등록번호와 일치하지 않은 인증서",
                },
              )
            },
          }
        }
        case "0002": {
          return {
            message: customMessage?.message ?? t("certification.error.taxInvoice"),
            type: customMessage?.type ?? "danger",
          }
        }
        case "9999": {
          return {
            message: t("certification.error.unexpected"),
            type: "danger",
          }
        }
        default: {
          return null
        }
      }
    },
    [t, workspaceId],
  )

  return {
    getCertificationMessage,
  } as const
}

type AuthStepIndicatorProps = {
  step: number
  total: number
}

export function AuthStepIndicator({ step, total }: AuthStepIndicatorProps) {
  return (
    <div className="mb-[60px] flex justify-center gap-2">
      {[...Array(total)].map((_, index) => (
        <div
          key={index}
          className={`h-1 w-10 ${
            index < step ? "bg-primary-07" : "bg-[#DFE1E5]"
          } rounded`}
        />
      ))}
    </div>
  )
}

"use client"
import * as React from "react"

import { loaderVariants } from "./loader.variants"

export function Loader() {
  const { loader } = loaderVariants()
  return (
    <div className={loader()}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

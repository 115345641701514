import { tv } from "tailwind-variants"

export const loaderVariants = tv({
  slots: {
    overlay: [
      "fixed left-0 top-0 z-[1400]",
      "h-full w-full",
      "flex items-center justify-center",
    ],
    loader: [
      "lds-ellipsis inline-block relative w-[80px] h-[80px]",
      "[&>div]:absolute [&>div]:top-[33px] [&>div]:w-[13px] [&>div]:h-[13px] [&>div]:rounded-full [&>div]:bg-[#ff6600] [&>div]:animate-[cubic-bezier(0,1,1,0)] [&>div]:transition-colors [&>div]:duration-200 [&>div]:ease-in-out",
      "[&>div:nth-child(1)]:animate-[lds-ellipsis1_0.6s_infinite]",
      "[&>div:nth-child(2)]:bg-[rgba(255,102,0,0.8)] [&>div:nth-child(2)]:left-[8px] [&>div:nth-child(2)]:animate-[lds-ellipsis2_0.6s_infinite]",
      "[&>div:nth-child(3)]:bg-[rgba(255,102,0,0.6)] [&>div:nth-child(3)]:left-[32px] [&>div:nth-child(3)]:animate-[lds-ellipsis2_0.6s_infinite]",
      "[&>div:nth-child(4)]:bg-[rgba(255,102,0,0.4)] [&>div:nth-child(4)]:left-[56px] [&>div:nth-child(4)]:animate-[lds-ellipsis3_0.6s_infinite]",
    ],
  },
})

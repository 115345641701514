import { CheckboxWithLink } from "../components"
import { POLICY_URL, TERMS_URL } from "../constants"

export interface AgreementProps {
  agree1: boolean
  agree2: boolean
  setAgree1: (value: boolean) => void
  setAgree2: (value: boolean) => void
}

export function Agreement({ agree1, agree2, setAgree1, setAgree2 }: AgreementProps) {
  return (
    <>
      <CheckboxWithLink
        isSelected={agree1}
        onValueChange={setAgree1}
        url={POLICY_URL}
        text="개인정보 수집 및 이용동의"
      />
      <CheckboxWithLink
        isSelected={agree2}
        onValueChange={setAgree2}
        url={TERMS_URL}
        text="이용약관 동의"
      />
    </>
  )
}

import { VariantProps } from "@nextui-org/react"
import { tv } from "tailwind-variants"

const textDividerVariants = tv({
  base: ["border-gray-05"],
  variants: {
    variant: {
      horizontal: ["w-[100%]", "border-b"],
      vertical: ["h-[10px]", "border-r"],
    },
  },
  defaultVariants: {
    variant: "vertical",
  },
})

type TextDividerVariants = VariantProps<typeof textDividerVariants>

type ElementType = HTMLDivElement
type ElementProps = React.HTMLAttributes<ElementType>

export type TextDividerProps = TextDividerVariants & ElementProps

export function TextDivider({
  className,
  variant,
  ...others
}: TextDividerVariants & ElementProps) {
  return <div {...others} className={textDividerVariants({ variant, className })} />
}

/**
 * api-gen이 nullable이 undefined로 정의되어 있어서 null 대신 undefined로 처리
 */
type DeepTrimmedValue<T> = T extends symbol
  ? symbol
  : T extends number
    ? number
    : T extends boolean
      ? boolean
      : T extends undefined
        ? undefined
        : T extends null
          ? undefined
          : T extends (infer U)[]
            ? DeepTrimmedValue<U>[]
            : T extends object
              ? { [K in keyof T]-?: DeepTrimmedValue<T[K]> }
              : // string type추론을 뺀 이유는 선언된 타입값(Enum 경우) string으로 인지되어 선언된 타입과 string은 그대로 유지
                T

export const trimValues = <T>(valueOrObj: T): DeepTrimmedValue<T> => {
  if (valueOrObj == null) {
    return undefined as DeepTrimmedValue<T>
  }

  if (typeof valueOrObj !== "object") {
    if (typeof valueOrObj === "string") {
      return (valueOrObj.trim() || undefined) as DeepTrimmedValue<T>
    }
    if (typeof valueOrObj === "number") {
      return (
        Number.isNaN(valueOrObj) ? undefined : valueOrObj
      ) as DeepTrimmedValue<T>
    }
    return valueOrObj as DeepTrimmedValue<T>
  }

  if (Array.isArray(valueOrObj)) {
    return valueOrObj.map(item => trimValues(item)) as DeepTrimmedValue<T>
  }

  return Object.entries(valueOrObj).reduce((acc, [key, value]) => {
    acc[key] = trimValues(value)
    return acc
  }, {} as DeepTrimmedValue<T>)
}

export const rules = {
  email: {
    required: "이메일을 입력해 주세요",
    pattern: {
      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "올바르지 않은 이메일 형식입니다.",
    },
  },
  password: {
    required: "비밀번호를 입력해 주세요",
  },
}

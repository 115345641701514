type RedirectToCertificationOptions = {
  redirectUrl?: string
}

export const redirectToCertification = (
  workspaceId: number,
  { redirectUrl }: RedirectToCertificationOptions = {},
) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/MagicLine4Web/ML4Web/certificate.html`
  const info = btoa(
    JSON.stringify({
      workspaceId,
      requestType: "certificateRegister",
      redirectUrl: redirectUrl || "/certification-result",
    }),
  )
  window.location.href = `${url}?info=${info}`
}

"use client"
import * as React from "react"
import { tv } from "tailwind-variants"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/navigation"

import Paper from "@/components/Paper/Paper"
import { formatBusinessRegistrationNumber } from "@/utils/formats"
import { TextDivider } from "@/components/Divider"
import { cn } from "@/utils/styles"

export type WorkspaceJoinByInvitationProps = {
  workspaceName: string
  representativeName: string
  businessRegistrationNumber: string
}

const variants = tv({
  slots: {
    container: "space-y-6",
    workspaceName: "typography-body-1-medium truncate",
    infoBlock: ["rounded-lg bg-[#F9F9F9] px-4 py-3"],
    infoGroupRow: "flex flex-col sm:flex-row sm:items-center gap-2",
    infoRow: "flex items-center gap-2",
    infoLabel: "typography-body-2-medium text-gray-08",
    infoValue: "typography-body-3-regular",
  },
})

export function WorkspaceJoinByInvitation({
  workspaceName,
  representativeName,
  businessRegistrationNumber,
}: WorkspaceJoinByInvitationProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    container,
    workspaceName: workspaceNameClass,
    infoBlock,
    infoRow,
    infoGroupRow,
    infoLabel,
    infoValue,
  } = variants()

  const onClickBackToLogin = () => {
    router.replace("/login")
  }

  return (
    <div className={container()}>
      <Paper>
        <Paper.Body>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className={workspaceNameClass()}>{workspaceName}</div>
            </div>

            <blockquote className={cn(infoBlock())}>
              <div className={infoGroupRow()}>
                <div className={infoRow()}>
                  <div className={infoLabel()}>
                    {t("auth.workspace.certification.representative")}
                  </div>
                  <div className={infoValue()}>{representativeName}</div>
                </div>
                <TextDivider variant="vertical" />
                <div className={infoRow()}>
                  <div className={infoLabel()}>
                    {t("auth.workspace.certification.businessNumber")}
                  </div>
                  <div className={infoValue()}>
                    {formatBusinessRegistrationNumber(businessRegistrationNumber)}
                  </div>
                </div>
              </div>
            </blockquote>
          </div>
        </Paper.Body>
      </Paper>

      <div>
        <button onClick={onClickBackToLogin} className="w-full text-sm text-gray-08">
          로그인 페이지로 돌아가기
        </button>
      </div>
    </div>
  )
}

import { Checkbox } from "@nextui-org/react"

export type CheckboxWithLinkProps = {
  isSelected: boolean
  onValueChange: (value: boolean) => void
  url: string
  text: string
}

export function CheckboxWithLink({
  isSelected,
  onValueChange,
  url,
  text,
}: CheckboxWithLinkProps) {
  return (
    <div className="flex items-center">
      <Checkbox
        radius="sm"
        isSelected={isSelected}
        classNames={{
          base: "flex",
          label: "text-sm text-gray-09",
          wrapper: "bolta-check-box",
        }}
        onValueChange={onValueChange}
      />
      <div className="text-sm text-gray-09">
        <a
          className="underline"
          href={url}
          target="_blank"
          onClick={() => onValueChange(true)}
        >
          {text}
        </a>
        <span className="ml-1 text-primary">(필수)</span>
      </div>
    </div>
  )
}

type RedirectPublicCertificationOptions = {
  workspaceId: number
  redirectUrl?: string
}

export const redirectPublicCertification = ({
  workspaceId,
  redirectUrl,
}: RedirectPublicCertificationOptions) => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/MagicLine4Web/ML4Web/certificate.html`
  const info = btoa(
    JSON.stringify({
      redirectUrl: redirectUrl || "/join/step2?joinType=join-by-certification",
      workspaceId,
      requestType: "joinWorkspaceWithCertificate",
    }),
  )
  window.location.href = `${url}?info=${info}`
}

import * as React from "react"
import { tv, VariantProps } from "tailwind-variants"

import { cn } from "@/utils/styles"

export const tagVariants = tv({
  base: [
    "min-h-[18px] inline-block",
    "text-[12px] font-medium leading-[18px]",
    "rounded px-2 py-1",
    "transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  ],
  defaultVariants: {
    intent: "primary",
  },
  variants: {
    intent: {
      primary: ["bg-primary-01 text-primary-08"],
      secondary: ["bg-navy-01 text-navy-08"],
      success: ["bg-green-01 text-green-08"],
      warning: ["bg-yellow-01 text-yellow-08"],
      danger: ["bg-red-01 text-red-08"],
    },
  },
})

type ElementType = HTMLDivElement
type ElementProps = React.HTMLAttributes<ElementType>
export type TagVariants = VariantProps<typeof tagVariants>

export interface TagProps extends ElementProps, TagVariants {}

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({ className, intent, ...props }, ref) => (
    <div ref={ref} className={cn(tagVariants({ intent }), className)} {...props} />
  ),
)
Tag.displayName = "Tag"

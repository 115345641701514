"use client"
import { Button } from "@nextui-org/react"
import dayjs from "dayjs"
import * as React from "react"
import { useQuery } from "@tanstack/react-query"
import { find, pipe } from "@fxts/core"

import GuardBlueIcon from "@/assets/icons/checked_badge.svg"
import GuardRedIcon from "@/assets/icons/guard-red.svg"
import Paper from "@/components/Paper/Paper"
import { DATE_FORMAT } from "@/constants/dates"
import { formatBusinessRegistrationNumber } from "@/utils/formats"
import { BoltaEvent } from "@/utils/mixpanels"
import { profileQueries } from "@/clients/profiles"
import { Loader, LoaderOverlay } from "@/components/Loaders"

import { redirectToCertification } from "../../utils"

interface WorkspaceRegisterCertificationProps {
  createdWorkspaceId: number
}

/**
 * 워크스페이스 생성 후 공동인증서를 등록하는 UI
 */
export function WorkspaceRegisterCertification({
  createdWorkspaceId,
}: WorkspaceRegisterCertificationProps) {
  const { data: profile, isPending: isProfilePending } = useQuery(
    profileQueries.profile(),
  )

  const workspaceGroup = React.useMemo(() => {
    return pipe(
      profile?.workspaces || [],
      find(group => group.workspace.id === createdWorkspaceId),
    )
  }, [profile?.workspaces, createdWorkspaceId])

  if (isProfilePending) {
    return (
      <LoaderOverlay>
        <Loader />
      </LoaderOverlay>
    )
  }

  const isCertificateNtsRegistered = workspaceGroup?.certificateNtsDataFetchRegistered
  return (
    <div>
      <Paper>
        <Paper.Body>
          <CompanyRegisterCompleted />

          <div className="bg-sub mb-6 rounded-lg px-4 py-3">
            <ul className="small-list check-bullet">
              <li>
                <div className="title">사업자등록번호</div>
                <div className="content">
                  {formatBusinessRegistrationNumber(
                    workspaceGroup?.workspace.businessRegistrationNumber || "",
                  )}
                </div>
              </li>
              <hr className="my-2" />
              <li>
                <div className="title">회사명</div>
                <div className="content">
                  {workspaceGroup?.workspace.organizationName}
                </div>
              </li>
              <hr className="my-2" />
              <li>
                <div className="title">대표자명</div>
                <div className="content">
                  {workspaceGroup?.workspace.representativeName}
                </div>
              </li>
            </ul>
          </div>

          {isCertificateNtsRegistered ? (
            <CertificationRegistered
              expiresAt={workspaceGroup?.certificates[0]?.expiresAtIso8601 || ""}
            />
          ) : (
            <CertificationRequired workspaceId={workspaceGroup?.workspace.id || 0} />
          )}
        </Paper.Body>
      </Paper>

      <Button
        size="lg"
        color="primary"
        className="mt-10 w-full opacity-100"
        onClick={() => {
          BoltaEvent.track("clicked__start__join_step2", {
            workspaceId: createdWorkspaceId,
          })
          window.location.href = "/settings/workspace"
        }}
      >
        시작하기
      </Button>
    </div>
  )
}

const CompanyRegisterCompleted = () => (
  <div className="bg-second mb-2 flex flex-wrap items-center rounded-lg px-4 py-[11px]">
    <GuardBlueIcon className="mr-2" />
    <div className="text-[15px]">회사정보 등록 완료</div>
  </div>
)

const CertificationRegistered = ({ expiresAt }: { expiresAt: string }) => {
  return (
    <>
      <div className="bg-second flex flex-wrap items-center rounded-lg px-4 py-[11px]">
        <GuardBlueIcon className="mr-2" />
        <div className="text-[15px]">공동인증서 등록 완료</div>
      </div>

      <div className="bg-sub mt-2 rounded-lg px-4 py-3">
        <ul className="small-list check-bullet">
          <li>
            <div className="title">유효기간</div>
            <div className="content">{dayjs(expiresAt).format(DATE_FORMAT.DATE)}</div>
          </li>
        </ul>
      </div>
    </>
  )
}

const CertificationRequired = ({ workspaceId }: { workspaceId: number }) => {
  return (
    <div className="flex flex-wrap items-center rounded-lg bg-[#FFF5F5] px-4 py-2">
      <GuardRedIcon className="mr-2" />
      <div className="text-[15px]">공동인증서 등록 필요</div>
      <Button
        size="sm"
        variant="bordered"
        className="white-btn ml-auto max-sm:hidden"
        onClick={() => {
          BoltaEvent.track("clicked__register_certificate__join_step2", {
            workspaceId,
          })
          redirectToCertification(workspaceId)
        }}
      >
        등록하기
      </Button>
    </div>
  )
}

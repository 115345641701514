import axios from "axios"

import { instance } from "@/app/(dashboard)/(sidebar)/api/api"
import { 계정 } from "@/clients/bolta-apis"
import { BaseResponse } from "@/clients/types"
import { useSnackbarStore } from "@/store/snackbarStore"

export const postSendPasswordResetAuthenticationCodeApi = async (
  requestBody: 계정.SendPasswordResetAuthenticationCode.RequestBody,
): Promise<boolean> => {
  try {
    await instance({
      method: "POST",
      url: "/api/v1/public/accounts:sendPasswordResetAuthenticationCode",
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const postSendEmailVerifyAuthenticationCodeApi = async (
  requestBody: 계정.SendEmailVerifyAuthenticationCode.RequestBody,
): Promise<boolean> => {
  try {
    await instance({
      method: "POST",
      url: "/api/v1/public/accounts:sendEmailVerifyAuthenticationCode",
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const postConfirmEmailVerifyAuthenticationCodeApi = async (
  requestBody: 계정.ConfirmEmailVerifyAuthenticationCode.RequestBody,
) => {
  const {
    data: { body },
  } = await instance<
    BaseResponse<계정.ConfirmEmailVerifyAuthenticationCode.ResponseBody>
  >({
    method: "POST",
    url: "/api/v1/public/accounts:confirmEmailVerifyAuthenticationCode",
    data: requestBody,
  })
  return body.token
}

export const postConfirmPasswordResetAuthenticationCodeApi = async (
  requestBody: 계정.ConfirmPasswordResetAuthenticationCode.RequestBody,
) => {
  const {
    data: { body },
  } = await instance<
    BaseResponse<계정.ConfirmPasswordResetAuthenticationCode.ResponseBody>
  >({
    method: "POST",
    url: "/api/v1/public/accounts:confirmPasswordResetAuthenticationCode",
    data: requestBody,
  })
  return body.token
}

export const signUpApi = async (
  data,
  invitationCode: string | null,
): Promise<boolean> => {
  const url = `/api/v1/public/accounts:signUp${
    invitationCode ? `?invitationCode=${invitationCode}` : ""
  }`

  try {
    await instance.post(url, data)

    return true
  } catch (error) {
    return false
  }
}

export const signInApi = async (
  email: string,
  password: string,
  invitationCode: string | null,
): Promise<void> => {
  const loginInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Accept: "application/json",
    },
    withCredentials: true,
  })

  const url =
    "/api/v1/public/accounts:signIn" +
    (invitationCode ? `?invitationCode=${invitationCode}` : "")

  loginInstance
    .post(url, {
      email: email,
      password: password,
    })
    .then(res => {
      const { accountId, isNew } = res.data.body
      let url = "/login-success"

      if (accountId) {
        url += `?accountId=${accountId}`
      }

      if (isNew !== undefined) {
        const prefix = accountId ? "&" : "?"
        url += `${prefix}isNew=${isNew}`
      }
      const prefix = accountId || isNew !== undefined ? "&" : "?"
      url += `${prefix}authorizedMethod=email`

      window.location.href = url
    })
    .catch(error => {
      const errorMessage =
        error.response.data?.body?.message ||
        error.response.data?.message ||
        "알 수 없는 오류가 발생했습니다."

      useSnackbarStore.getState().openSnackbar(errorMessage, "danger")
    })
}

// 로그인 - 비밀번호 찾기
export const putResetPasswordApi = async (
  password: string,
  token: string,
): Promise<boolean> => {
  const url = "/api/v1/public/accounts:resetPassword"

  try {
    await instance.put(url, { password, token })

    return true
  } catch (error) {
    return false
  }
}

// 설정: 비밀번호 변경
export const putChangePasswordApi = async (
  { accountId }: 계정.ResetPassword1.RequestParams,
  requestBody: 계정.ResetPassword1.RequestBody,
): Promise<boolean> => {
  try {
    await instance<BaseResponse<계정.ResetPassword1.ResponseBody>>({
      method: "PUT",
      url: `/api/v1/accounts/${accountId}:resetPassword`,
      data: requestBody,
    })
    return true
  } catch (error) {
    return false
  }
}

export const putAccountApi = async ({
  accountId,
  ...requestBody
}: 계정.UpdateAccountProfile.RequestParams &
  계정.UpdateAccountProfile.RequestBody) => {
  const {
    data: { body },
  } = await instance<BaseResponse<계정.UpdateAccountProfile.ResponseBody>>({
    method: "PUT",
    url: `/api/v1/accounts/${accountId}`,
    data: requestBody,
  })
  return body
}

/* eslint-disable  @typescript-eslint/no-explicit-any */

type WithComponentComposite<
  MainComponent extends React.ComponentType<any>,
  Subcomponents extends Record<string, React.ComponentType<any>>,
> = MainComponent & {
  [K in keyof Subcomponents]: Subcomponents[K]
}

function withComponentComposite<
  MainComponent extends React.ComponentType<any>,
  Subcomponents extends Record<string, React.ComponentType<any>>,
>(
  Main: MainComponent,
  subcomponents: Subcomponents,
): WithComponentComposite<MainComponent, Subcomponents> {
  Object.keys(subcomponents).forEach(key => {
    ;(Main as any)[key] = subcomponents[key]
  })

  return Main as WithComponentComposite<MainComponent, Subcomponents>
}

export default withComponentComposite

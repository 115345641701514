import React from "react"

import withComponentComposite from "@/utils/componentComposite"
import { cn } from "@/utils/styles"

type PaperProps = {
  children: React.ReactNode
  className?: string
}

type PaperHeaderProps = {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  action?: React.ReactNode
  headerAs?: string
  titleAs?: string
  descriptionAs?: string
}

type PaperBodyProps = {
  children: React.ReactNode
  className?: string
}

const Paper = ({ children, className }: PaperProps) => {
  return (
    <div
      className={cn(
        "rounded-lg border border-tertiary bg-white p-4 max-sm:rounded-none md:p-6",
        className,
      )}
    >
      {children}
    </div>
  )
}

const PaperHeader = ({
  title,
  description,
  action,
  headerAs,
  titleAs,
  descriptionAs,
}: PaperHeaderProps) => {
  return (
    <>
      <div className={cn("mb-4 flex justify-between", headerAs)}>
        <div>
          <div className={cn("text-[18px] font-semibold text-[#171D23]", titleAs)}>
            {title}
          </div>
          {description && (
            <p className={cn("mt-1 text-[14px] text-gray-08", descriptionAs)}>
              {description}
            </p>
          )}
        </div>
        {action && <div>{action}</div>}
      </div>
    </>
  )
}

const PaperBody = ({ children, className }: PaperBodyProps) => {
  return <div className={cn(className)}>{children}</div>
}

export default withComponentComposite(Paper, {
  Header: PaperHeader,
  Body: PaperBody,
})

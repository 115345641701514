import { tv } from "tailwind-variants"

import { cn } from "@/utils/styles"

type AuthJoinHeaderTitleProps = {
  className?: string
  children: string
}

type AuthJoinHeaderContentProps = {
  className?: string
  children: React.ReactNode
}

type AuthJoinHeaderProps = {
  className?: string
  children: React.ReactNode
}

const variants = tv({
  slots: {
    title: "mb-4 text-[24px] font-bold",
    content: "mb-10 text-gray-09",
    container: "text-center",
  },
})

export function AuthJoinHeaderTitle({
  className,
  children,
}: AuthJoinHeaderTitleProps) {
  const { title } = variants()
  return <div className={cn(title(), className)}>{children}</div>
}

export function AuthJoinHeaderContent({
  className,
  children,
}: AuthJoinHeaderContentProps) {
  const { content } = variants()
  return <div className={cn(content(), className)}>{children}</div>
}

export function AuthJoinHeader({ className, children }: AuthJoinHeaderProps) {
  const { container } = variants()
  return <div className={cn(container(), className)}>{children}</div>
}

AuthJoinHeader.Title = AuthJoinHeaderTitle
AuthJoinHeader.Content = AuthJoinHeaderContent
